<template>
  <LandingPageSection class="section-gray">
    <ProductPeriodSelector v-model="period" />
    <ProductCardGroup :period="period" :showJfd="true" class="product-card-container mt-lg-5 mt-4" />
  </LandingPageSection>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import ProductPeriodSelector from "@/views/pages-landing/product/ProductPeriodSelector.vue";
import LandingPageSection from "@/views/pages-landing/sections/LandingPageSection.vue";
import { storeToRefs } from "pinia";
import { paymentStore } from "@/stores/payment-store";
import { Timeframe } from "@/anfin-chart/time/timeframe";
import type { ShoppingProduct } from "@/api/models/shopping-cart";
import ProductCardGroup from "@/views/pages-landing/product/ProductCardGroup.vue";

export default defineComponent({
  name: "ProductSection",

  components: { ProductCardGroup, LandingPageSection, ProductPeriodSelector },

  expose: [],

  data() {
    const { activeProducts } = storeToRefs(paymentStore());
    return {
      // TODO: period dynamic based on billwerk data
      period: Timeframe.MN1,
      products: activeProducts as unknown as ShoppingProduct[]
    };
  }
});
</script>

<style scoped>
.product-card-container {
  display: flex;
  flex-direction: row;
  gap: 50px;
  justify-content: center;
  flex-wrap: wrap;
}

@media (max-width: 1199px) {
  .product-card-container {
    gap: 30px;
  }
}
</style>