import type { ShoppingProduct, ShoppingSubscription } from "@/api/models/shopping-cart";
import { ShoppingCart, ShoppingCartItem } from "@/api/models/shopping-cart";
import { PaymentController } from "@/api/payment-controller";
import { defineStore } from "pinia";
import {
  PaymentCountry,
  ShoppingCartItemResolved,
  ShoppingCartResolved,
  ShoppingOrderState
} from "@/api/models/checkout";
import type { Consumer } from "@/anfin-chart/utils";
import { CustomerData } from "@/api/models/customer-data";

export const paymentStore = defineStore({
  id: "payment",

  state() {
    setTimeout(() => initializeStore());
    return {
      paymentController: PaymentController.getInstance(),
      productMap: new Map<string, ShoppingProduct>(),
      countries: [] as PaymentCountry[],
      currentCart: null as ShoppingCart | null,
      orderState: new ShoppingOrderState(),
      isShowCart: false,
      pendingActions: [] as Consumer<void>[]
    };
  },

  getters: {
    products(): ShoppingProduct[] {
      return Array.from(this.productMap.values());
    },

    activeProducts(): ShoppingProduct[] {
      return this.products.filter(p => p.isActive && p.activeVariants.length > 0);
    },

    getProduct() {
      return (id: string) => this.productMap.get(id) ?? null;
    },

    currentCartData(): ShoppingCartResolved | null {
      if (this.currentCart == null) {
        return null;
      }
      let hasError = false;
      const items = [];
      for (const item of this.currentCart.items) {
        const product = this.productMap.get(item.productId);
        const variant = product?.variants.find(v => v.id === item.variantId);
        if (product == null || variant == null) {
          hasError = true;
          continue;
        }
        const resolvedItem = new ShoppingCartItemResolved(product, variant);
        items.push(resolvedItem);
      }
      return new ShoppingCartResolved(this.currentCart.id, hasError, this.currentCart.couponCode, items);
    }
  },

  actions: {
    async requestProducts() {
      const products = await this.paymentController.getProducts();
      this.productMap.clear();
      for (const product of products) {
        this.productMap.set(product.id, product);
      }
    },

    async requestCountries() {
      this.countries = await this.paymentController.getCountries();
    },

    async initializeCart() {
      const cachedCartId = localStorage.getItem("cartId");
      if (cachedCartId != null) {
        await this.loadCart(cachedCartId);
      }
      if (this.currentCart == null) {
        await this.createCart();
      }
      this.pendingActions.forEach(a => a());
      this.pendingActions = [];
    },

    onInitializationCompleted(action: Consumer<void>) {
      if (this.currentCart == null) {
        this.pendingActions.push(action);
      } else {
        action();
      }
    },

    async getSubscriptions(email: string) {
      return await this.paymentController.getSubscriptions(email);
    },

    async loadCart(cartId: string) {
      const cart = await this.paymentController.getCart(cartId);
      await this.setCart(cart);
    },

    async createCart() {
      const id = await this.paymentController.createCart();
      const cart = new ShoppingCart(id, "", []);
      await this.setCart(cart);
      return cart;
    },

    async addCartItem(productId: string, variantId: string) {
      let cart = this.currentCart;
      if (cart == null) {
        cart = await this.createCart();
      }
      let hasItem = false;
      for (const item of cart.items) {
        if (item.productId === productId) {
          item.variantId = variantId;
          hasItem = true;
          break;
        }
      }
      if (!hasItem) {
        cart.items.push(new ShoppingCartItem(productId, variantId));
      }
      await this.updateCart();
      await this.loadCart(cart.id);
    },

    async removeCartItem(productId: string, variantId: string) {
      if (this.currentCart == null) {
        return;
      }
      for (let i = 0; i < this.currentCart.items.length; i++) {
        const item = this.currentCart.items[i];
        if (item.productId === productId && item.variantId === variantId) {
          this.currentCart.items.splice(i, 1);
          break;
        }
      }
      await this.updateCart();
    },

    async updateCart() {
      if (this.currentCart == null) {
        return;
      }
      await this.paymentController.updateCart(this.currentCart);
      await this.createOrderPreview();
    },

    async applyCouponCode(couponCode: string) {
      if (this.currentCart == null) {
        return;
      }
      this.currentCart.couponCode = couponCode;
      await this.paymentController.applyCouponCode(this.currentCart);
      await this.createOrderPreview();
      await this.createOrder();
    },

    async deleteCart() {
      this.onInitializationCompleted(() => {
        if (this.currentCart != null) {
          this.paymentController.deleteCart(this.currentCart);
          this.setCart(null);
          this.createCart();
        }
      });
    },

    async createOrder() {
      if (this.currentCart == null) {
        return;
      }
      const orderData = await this.paymentController.createOrder(this.currentCart.id);
      this.orderState.setOrderData(orderData);
    },

    async createOrderPreview() {
      if (this.currentCart == null) {
        return;
      }
      const orderData = await this.paymentController.createOrderPreview(this.currentCart.id);
      this.orderState.setOrderData(orderData);
    },

    async getUserAddressData() {
      if (this.currentCart == null) {
        return new CustomerData();
      }
      return await this.paymentController.getUserAddressData(this.currentCart.id);
    },

    async saveUserAddressData() {
      if (this.currentCart == null) {
        return;
      }
      const orderData = await this.paymentController.saveUserAddressData(this.currentCart.id, this.orderState.customerData);
      this.orderState.setOrderData(orderData);
    },

    async requestSelfServiceToken(contractId: string) {
      const selfServiceToken = await this.paymentController.requestSelfServiceToken(contractId);
      if (selfServiceToken.selfServiceToken.includes("https")) {
        window.open(selfServiceToken.selfServiceToken, "_blank");
      }
    },

    async requestTermination(subscription: ShoppingSubscription) {
      return await this.paymentController.requestTermination(subscription);
    },

    async requestRevocation(subscription: ShoppingSubscription) {
      return await this.paymentController.requestRevocation(subscription);
    },

    async requestTerminationInfo(subscription: ShoppingSubscription) {
      return this.paymentController.requestTerminationInfo(subscription);
    },

    async requestCancelTermination(subscription: ShoppingSubscription) {
      return await this.paymentController.requestCancelTermination(subscription);
    },

    async setCart(cart: ShoppingCart | null) {
      this.currentCart = cart;
      if (cart == null) {
        localStorage.removeItem("cartId");
      } else {
        localStorage.setItem("cartId", cart.id);
        await this.createOrderPreview();
      }
    },

    setShowCart(isShowCart: boolean) {
      this.isShowCart = isShowCart;
    }
  }
});

async function initializeStore() {
  const store = paymentStore();
  await Promise.all([
    store.requestProducts(),
    store.requestCountries(),
    store.initializeCart()
  ]);
}
