<template>
  <div class="checkout-summary">
    <h5>{{ translationStore.getTranslation("checkout#overview_header") }}</h5>
    <div class="label-group mt-3">
      <div class="label-group-item">
        <div>
          {{ translationStore.getTranslation("checkout#total_net") }}
        </div>
        <div>
          {{ formatPrice(orderState.totalNet) }}
        </div>
      </div>
      <div class="label-group-item mt-2">
        <div>
          {{ translationStore.getTranslation("checkout#total_vat") }}
        </div>
        <div>
          {{ formatPrice(orderState.totalVat) }}
        </div>
      </div>
      <div class="label-group-item mt-2 font-bold">
        <div>
          {{ translationStore.getTranslation("checkout#total_gross") }}
        </div>
        <div>
          {{ formatPrice(orderState.totalGross) }}
        </div>
      </div>
    </div>
    <div v-if="orderState.coupon !== ''" class="label-group-item mt-3 mb-1 font-bold">
      <div>
        <!-- TODO add translation -->
        verwendeter Code:
      </div>
      <div>
        {{ orderState.coupon }}
      </div>
    </div>

    <div v-if="orderState.nextTotalGross !== orderState.totalGross" class="label-group-item mt-3 mb-2 font-bold">
      <div>
        <!-- TODO add translation -->
        Am {{ formatDate(orderState.nextTotalGrossDate) }} wird die nächste Gebühr von {{ formatPrice(orderState.nextTotalGross) }} fällig!
      </div>
    </div>
    <button type="button" class="btn btn-primary mt-3" :disabled="!allowNext" @click="goToNextStep">
      {{ nextLabel }}
    </button>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { translationStore } from "@/stores/translation-store";
import { MonetaryMixin } from "@/mixins/monetary-mixin";
import { storeToRefs } from "pinia";
import { paymentStore } from "@/stores/payment-store";
import type { ShoppingOrderState } from "@/api/models/checkout";
import {DateMixin} from "@/mixins/date-mixin";

export default defineComponent({
  name: "CheckoutSummary",

  mixins: [MonetaryMixin, DateMixin],

  props: {
    allowNext: Boolean,
    nextLabel: {
      type: String,
      required: true
    }
  },

  emits: {
    next: () => true
  },

  expose: [],

  data() {
    const { orderState } = storeToRefs(paymentStore());
    return {
      translationStore: translationStore(),
      orderState: orderState as unknown as ShoppingOrderState
    };
  },

  methods: {
    goToNextStep() {
      this.$emit("next");
    }
  }
});
</script>

<style scoped>
.checkout-summary {
  padding: 1.5em 1em;
  border: 1px solid var(--border-overlay);
  border-radius: var(--border-radius-single);
  order: 2;
  width: 300px;
}

@media screen and (min-width: 992px) {
  .checkout-summary {
    text-align: center;
    flex-basis: 300px;
    flex-shrink: 0;
  }
}

.label-group {
  text-align: left;
  display: flex;
  flex-direction: column;
}

.label-group-item {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
  align-items: stretch;
}
</style>