import type { Drawable } from "@/anfin-chart/drawable";
import { TextBox } from "@/anfin-chart/drawable";
import type { ToolHint } from "@/anfin-chart/hints/tool-hint";
import type { Instrument } from "@/anfin-chart/instrument";
import type { SubChart } from "@/anfin-chart/sub-chart";
import type { Timeframe } from "@/anfin-chart/time/timeframe";
import type { ChartToolPoint, FixedPoint } from "@/anfin-chart/tools/tool-point";
import { Point } from "@/anfin-chart/geometry";
import type { AlertToolData } from "@/anfin-chart/tools/alert-tool";
import type { Alert, AlertRule, AlertRuleDefinition } from "@/api/models/alert";
import { SubscriptionManager } from "@/anfin-chart/subscription";
import type { Deletable } from "@/anfin-chart/interactions";

export abstract class ChartTool implements Deletable {

  public isHovered = false;
  public readonly hints: ToolHint[] = [];
  protected readonly drawables: Drawable[] = [];
  protected readonly points: ChartToolPoint[] = [];
  protected readonly updateCallbacks: (() => void)[] = [];
  protected readonly alertDatas: AlertToolData[] = [];
  protected readonly subscriptionManager = new SubscriptionManager();

  private readonly positionMap = new Map<number, Point>();
  private isUpdating = false;

  constructor(public readonly instrument: Instrument,
              public readonly timeframe: Timeframe,
              public subChart: SubChart) {
    setTimeout(() => {
      this.resetDrawables();
      this.subscriptionManager.subscribe(this.chart.getPixelRatioObservable(), () => this.updateDrawables());
    });

  }

  public get chart() {
    return this.subChart.chart;
  }

  protected get optionManager() {
    return this.chart.optionManager;
  }

  public onDelete() {
    this.subscriptionManager.unsubscribeAll();
  }

  public isSelectionAllowed() {
    return true;
  }

  public onHoverChange() {
    this.isHovered = this.drawables.some(d => d.isHovered);
    this.updateDrawables();
  }

  public getSubChart() {
    return this.subChart;
  }

  public setSubChart(subChart: SubChart) {
    if (this.subChart === subChart) {
      return;
    }
    this.subChart = subChart;
    this.updateDrawables();
  }

  public addDrawable(drawable: Drawable) {
    this.drawables.push(drawable);
  }

  public updatePoint(point: ChartToolPoint, position: Point) {
    this.positionMap.set(point.id, position);
  }

  public updateFixedPoint(...points: FixedPoint[]) {
    for (const point of points) {
      const timeAxis = this.chart.timeAxis;
      const x = timeAxis.getXForTime(point.time);
      const y = this.subChart.priceAxis.getY(point.price);
      this.updatePoint(point, new Point(x, y));
    }
  }

  public getPosition(point: ChartToolPoint) {
    return this.positionMap.get(point.id) ?? new Point(0, 0);
  }

  public getDrawables() {
    return this.drawables;
  }

  public updateDrawables() {
    if (this.isUpdating) {
      return;
    }
    if (!this.isSelectionAllowed()) {
      for (const drawable of this.drawables) {
        drawable.ignoreHit();
      }
    }
    this.isUpdating = true;
    for (const callback of this.updateCallbacks) {
      callback();
    }
    this.updatePosition();
    this.chart.toolLayer.requireDraw();
    this.isUpdating = false;
  }

  public updatePosition() {
    this.positionMap.clear();
    this.updatePositionInternal();
    for (const data of this.alertDatas) {
      this.updateAlertPosition(data);
    }
    for (const drawable of this.drawables) {
      if (drawable instanceof TextBox) {
        drawable.updatePosition();
      }
    }
    this.chart.toolLayer.requireDraw();
  }

  public onUpdate(callback: () => void) {
    this.updateCallbacks.push(callback);
  }

  public resetDrawables() {
    this.drawables.splice(0);
    this.updateCallbacks.splice(0);
    this.createDrawables();
    this.createAlertDatas();
    for (let i = 0; i < this.alertDatas.length; i++) {
      const data = this.alertDatas[i];
      this.createAlertDrawables(data, i);
    }
    this.updatePosition();
    this.updateDrawables();
  }

  private createAlertDatas() {
    for (const data of this.alertDatas) {
      if (data.marker != null) {
        this.subChart.priceAxis.removeMarker(data.marker);
      }
    }
    this.alertDatas.splice(0);
    const alerts = new Set(this.chart.getAlertTools().map(t => t.alert));
    for (const alert of alerts) {
      for (const rule of alert.rules) {
        for (const definition of rule.definitions) {
          this.createAlertData(alert, rule, definition);
        }
      }
    }
  }

  public abstract getIsVisible(): boolean;

  protected abstract createDrawables(): void;

  protected abstract updatePositionInternal(): void;

  protected abstract createAlertData(alert: Alert, rule: AlertRule, definition: AlertRuleDefinition): void;

  protected abstract createAlertDrawables(data: AlertToolData, index: number): void;

  protected abstract updateAlertPosition(data: AlertToolData): void;
}
