<template>
  <div class="product-table" :style="tableStyle">
    <slot :productDatas="productDatas" :period="period" :setPeriod="setPeriod"></slot>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { Timeframe } from "@/anfin-chart/time/timeframe";
import { storeToRefs } from "pinia";
import { paymentStore } from "@/stores/payment-store";
import type { ShoppingProduct } from "@/api/models/shopping-cart";
import { createProductComparisonData, ProductFeatureData } from "@/api/models/product-comparison";

export default defineComponent({
  name: "ProductTable",

  expose: [],

  data() {
    const { activeProducts } = storeToRefs(paymentStore());
    return {
      // TODO: period dynamic based on billwerk data
      period: Timeframe.MN1,
      products: activeProducts as unknown as ShoppingProduct[],
    };
  },

  computed: {
    productDatas(): ProductFeatureData[] {
      const productDatas = [];
      for (const product of this.products) {
        const productData = createProductComparisonData(product);
        if (productData == null) {
          console.error("Could not find product comparison data: " + product.key);
        } else {
          productDatas.push(productData);
        }
      }
      return productDatas;
    },

    tableStyle() {
      const columnCount = this.productDatas.length + 1;
      return {
        gridTemplateColumns: "repeat(" + columnCount + ", 1fr)"
      };
    }
  },

  methods: {
    setPeriod(period: Timeframe) {
      this.period = period;
    }
  }
});
</script>

<style scoped>
.product-table {
  display: grid;
  gap: 0 60px;
  align-items: start;
  overflow-x: auto;
}

@media (max-width: 1199px) {
  .product-table {
    gap: 20px;
  }
}
</style>