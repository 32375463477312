import type { PlotDataItem } from "@/anfin-chart/draw/plot-data";
import { ChartError } from "@/anfin-chart/error";
import type { ColorOption } from "@/anfin-chart/options/option";
import type { DataItem } from "@/anfin-chart/plot";
import { padLeft, type Producer } from "@/anfin-chart/utils";

export class ColorSet {

  constructor(public readonly index: number,
              public readonly colors: ColorOption[]) {
  }
}

export abstract class ColorProvider {

  public readonly colorSets: ColorSet[] = [];
  public currentSet: ColorSet | null = null;
  public lastSet: ColorSet | null = null;
  public switchPercentage: number | null = null;

  protected lastValue = 0;
  private colorSetCounter = 0;

  public reset() {
    this.currentSet = null;
    this.lastSet = null;
    this.switchPercentage = null;
    this.lastValue = 0;
  }

  public update(item: PlotDataItem) {
    const index = this.resolveIndex(item);
    const colorSet = this.colorSets[index];
    this.lastSet = this.currentSet;
    this.currentSet = colorSet;
    this.lastValue = item.values[0];
  }

  public getPrimaryColor() {
    return this.colorSets[0].colors[0];
  }

  protected createColorSet(colors: ColorOption[]) {
    const colorSet = new ColorSet(this.colorSetCounter++, colors);
    this.colorSets.push(colorSet);
  }

  protected abstract resolveIndex(item: PlotDataItem): number;
}

export class SimpleColorProvider extends ColorProvider {

  constructor(public readonly colors: ColorOption[]) {
    super();
    this.createColorSet(colors);
  }

  protected override resolveIndex() {
    return 0;
  }
}

export class ThresholdColorProvider extends ColorProvider {

  constructor(public readonly threshold: number,
              public readonly upper: ColorOption[],
              public readonly lower: ColorOption[]) {
    super();
    this.createColorSet(upper);
    this.createColorSet(lower);
  }

  protected override resolveIndex(item: PlotDataItem) {
    const index = item.values[0] >= this.threshold ? 0 : 1;
    if (this.currentSet != null && index !== this.currentSet.index) {
      const currentValue = item.values[0];
      this.switchPercentage = (this.threshold - this.lastValue) / (currentValue - this.lastValue);
    }
    return index;
  }
}

export class MultiKeyColorProvider extends ColorProvider {

  private readonly keyIndexMap = new Map<string, number>();

  constructor(private readonly keys: string[],
              keyColors: ColorOption[][]) {
    super();
    if (keys.length !== keyColors.length) {
      throw new ChartError("Invalid multi key color definition");
    }
    for (let i = 0; i < keys.length; i++) {
      const key = keys[i];
      this.keyIndexMap.set(key, i);
      this.createColorSet(keyColors[i]);
    }
  }

  protected override resolveIndex(item: PlotDataItem) {
    const key = item.baseItem.key;
    const index = key == null ? null : this.keyIndexMap.get(key);
    if (index == null) {
      console.error("Could not resolve color key: " + key);
      return 0;
    }
    return index;
  }
}

export class ConditionalColorProvider extends ColorProvider {

  private readonly conditions: Producer<DataItem, number>[];
  private lastConditionIndex: number | null = null;
  private lastConditionValues: number[] | null = null;

  constructor(conditions: string[],
              conditionColors: ColorOption[][]) {
    super();
    if (conditions.length !== conditionColors.length - 1) {
      throw new ChartError("Invalid condition color definition");
    }
    this.conditions = conditions.map(c => new Function("item", "return " + c) as Producer<DataItem, number>);
    for (const conditionColor of conditionColors) {
      this.createColorSet(conditionColor);
    }
  }

  protected override resolveIndex(item: PlotDataItem) {
    let index: number | null = null;
    const conditionValues = [];
    for (let i = 0; i < this.conditions.length; i++) {
      const conditionValue = this.conditions[i](item.baseItem);
      conditionValues.push(conditionValue);
      if (index == null && conditionValue >= 0) {
        index = i;
      }
    }
    if (index == null) {
      index = this.conditions.length;
    }
    if (index !== this.lastConditionIndex && this.lastConditionIndex != null && this.lastConditionValues != null) {
      this.onColorSwitch(this.lastConditionIndex, index, this.lastConditionValues, conditionValues);
    }
    this.lastConditionIndex = index;
    this.lastConditionValues = conditionValues;
    return index;
  }

  private onColorSwitch(lastIndex: number, index: number, lastConditionValues: number[], conditionValues: number[]) {
    if (index < lastIndex) {
      const lastValue = Math.abs(lastConditionValues[index]);
      const currentValue = conditionValues[index];
      this.switchPercentage = lastValue / (lastValue + currentValue);
    } else {
      const lastValue = lastConditionValues[lastIndex];
      const currentValue = Math.abs(conditionValues[lastIndex]);
      this.switchPercentage = 1 - currentValue / (lastValue + currentValue);
    }
  }
}

export abstract class ChartColor {
}

export interface RGBA {
  r: number;
  g: number;
  b: number;
  a: number;
}

export class RGBAColor extends ChartColor implements RGBA {

  public static readonly transparent = new RGBAColor(0, 0, 0, 0);
  public static readonly black = new RGBAColor(0, 0, 0, 1);
  public static readonly white = new RGBAColor(255, 255, 255, 1);

  constructor(public readonly r: number,
              public readonly g: number,
              public readonly b: number,
              public readonly a = 1) {
    super();
  }

  public static fromHex(hexInput: string) {
    const hex = hexInput.trim();
    const r = parseInt(hex.substring(1, 3), 16);
    const g = parseInt(hex.substring(3, 5), 16);
    const b = parseInt(hex.substring(5, 7), 16);
    const a = hex.length > 7 ? parseInt(hex.substring(7, 9), 16) / 255 : 1;
    if (isNaN(r) || isNaN(g) || isNaN(b) || isNaN(a)) {
      console.error("Invalid hex color: " + hex);
      return RGBAColor.black;
    }
    return new RGBAColor(r, g, b, a);
  }

  public static fromRgba(rgba: RGBA) {
    return new RGBAColor(rgba.r, rgba.g, rgba.b, rgba.a);
  }

  public withAlpha(alpha: number) {
    return new RGBAColor(this.r, this.g, this.b, alpha);
  }
}

function getHexComponent(value: number) {
  return padLeft(value.toString(16), "0", 2);
}

export function convertToHex(rgba: RGBA) {
  return "#" + getHexComponent(Math.round(rgba.r)) +
    getHexComponent(Math.round(rgba.g)) +
    getHexComponent(Math.round(rgba.b)) +
    getHexComponent(Math.round(rgba.a * 255));
}

export function convertToRgbaCss(rgba: RGBA) {
  return `rgb(${rgba.r} ${rgba.g} ${rgba.b} / ${rgba.a})`;
}

export interface GradientStop {
  percentage: number;
  color: RGBAColor;
}

export class GradientColor extends ChartColor {

  public stops: GradientStop[] = [];

  public addStop(percentage: number, color: RGBAColor) {
    this.stops.push({ percentage, color });
    return this;
  }

  public colorAt(percentage: number) {
    let index = 0;
    while (index < this.stops.length && this.stops[index].percentage < percentage) {
      index++;
    }
    if (index === 0) {
      return this.stops[0].color;
    }
    if (index >= this.stops.length) {
      return this.stops[this.stops.length - 1].color;
    }
    const lastStop = this.stops[index - 1];
    const stop = this.stops[index];
    const percentageBetween = (percentage - lastStop.percentage) / (stop.percentage - lastStop.percentage);
    return new RGBAColor(
      lastStop.color.r + (stop.color.r - lastStop.color.r) * percentageBetween,
      lastStop.color.g + (stop.color.g - lastStop.color.g) * percentageBetween,
      lastStop.color.b + (stop.color.b - lastStop.color.b) * percentageBetween,
      lastStop.color.a + (stop.color.a - lastStop.color.a) * percentageBetween
    );
  }
}
