import { defineStore } from "pinia";
import { ChatController } from "@/api/chat-controller";
import type { Chat, ChatMessage } from "@/api/models/chat";
import { chartOptionStore } from "@/stores/chart-option-store";
import { simpleCompare } from "@/anfin-chart/utils";
import { notificationStore } from "@/stores/notification-store";

function compareChat(first: Chat, second: Chat) {
  const unitCompare = simpleCompare(first.prio, second.prio) * -1;
  if (unitCompare !== 0) {
    return unitCompare;
  }
  return simpleCompare(first.title, second.title);
}

export const chatStore = defineStore({
  id: "chat",

  state() {
    setTimeout(() => initializeStore());
    return {
      chatsMap: new Map<number, Chat>()
    };
  },

  getters: {
    chats(): Chat[] {
      return Array.from(this.chatsMap.values()).sort(compareChat);
    },

    unseenMessageCount() {
      const chats = chatStore().chats;
      let unseenMessageCount = 0;
      for (const chat of chats) {
        unseenMessageCount += chat.getUnseenMessageCount();
      }
      if (unseenMessageCount > 0 && unseenMessageCount !== this.unseenMessageCount) {
        notificationStore().playSelectedSound();
      }
      return unseenMessageCount;
    }
  },

  actions: {
    initializeSelectedChats() {
      const option = chartOptionStore().optionManager.selectedChats;
      const isInitialValue = option.getValue()[0] === -1;
      if (isInitialValue) {
        option.setValue(this.chats.map(c => c.id));
      }
    },

    async requestChats() {
      const chats = await ChatController.getInstance().requestChats();
      for (const chat of chats) {
        this.addChat(chat);
      }
      this.initializeSelectedChats();
      await Promise.all([
        this.requestAllChatMessages()
      ]);
    },

    getChat(id: number) {
      return this.chatsMap.get(id) ?? null;
    },

    addChat(chat: Chat) {
      this.chatsMap.set(chat.id, chat);
    },

    addMessage(message: ChatMessage) {
      message.chat.addMessage(message);
    },

    async saveMessage(message: ChatMessage) {
      await ChatController.getInstance().saveMessage(message);
    },

    async markRead(chat: Chat) {
      const lastMessage = chat.getLastMessage();
      if (lastMessage != null && lastMessage.id != null && lastMessage.id > chat.getLastSeenMessageId()) {
        await ChatController.getInstance().setLastSeenMessage(chat, lastMessage);
      }
    },

    async requestAllChatMessages() {
      const promises = [];
      for (const chat of this.chatsMap.values()) {
        const promise = this.requestChatMessages(chat.id);
        promises.push(promise);
      }
      await Promise.all(promises);
    },

    async requestChatMessages(chatId: number) {
      const chat = this.chatsMap.get(chatId);
      if (chat != null) {
        const newMessages = await ChatController.getInstance().requestMessages(chat);
        chat.clear();
        chat.addMessage(...newMessages);
      }
    },

    async deleteMessage(message: ChatMessage) {
      message.chat.removeMessage(message);
      await ChatController.getInstance().deleteMessage(message);
    }
  }
});

async function initializeStore() {
  const store = chatStore();
  await store.requestChats();
}
